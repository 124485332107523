
// src/components/AssetList.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Button, Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, TextField, InputAdornment } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SearchIcon from '@mui/icons-material/Search';
import DownloadIcon from '@mui/icons-material/Download';
import { CSVLink } from 'react-csv';
import dayjs from 'dayjs';

const AssetList = () => {
  const [assets, setAssets] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const source = axios.CancelToken.source(); // Create a source object for cancellation

    // Fetch assets from the API
    axios.get(`${process.env.REACT_APP_BASE_URL}/auth/assets`, { cancelToken: source.token })
    .then(response => {
      console.log('Fetched Assets:', response.data);
      setAssets(response.data);
    })
    .catch(error => {
      if (!axios.isCancel(error)) {
        console.error('Error fetching assets:', error);
      }
    });

    return () => {
      source.cancel('Component unmounted. Request canceled.');
    };
  }, []);

  const handleAsset = () => {
    navigate('/dashboard/addAsset');
  };

  const handleEdit = (assetId) => {
    navigate(`/dashboard/editAssets/${assetId}`);
  };

     const handleDelete = async (assetId) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this user?');
    if (!confirmDelete) return;

    try {
      await axios.delete(`${process.env.REACT_APP_BASE_URL}/auth/assets/${assetId}`);
      setAssets(prevAssets => prevAssets.filter(asset => asset.id !== assetId));
    } catch (error) {
      console.error('Error deleting asset:', error);
    }
  };


  const handleVerify = (assetId) => {
    navigate(`/dashboard/verifyAssets/${assetId}`);
  };

  const filteredAssets = assets.filter((asset) =>
    asset.code.toLowerCase().includes(searchTerm.toLowerCase()) ||
    asset.serialNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
    (asset.Vendor && asset.Vendor.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
    (asset.Entity && asset.Entity.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
    (asset.Branch && asset.Branch.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
    (asset.AssetType && asset.AssetType.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
    asset.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const headers = [
    { label: 'Code', key: 'code' },
    { label: 'Serial Number', key: 'serialNumber' },
    { label: 'Vendor', key: 'Vendor.name' },
    { label: 'Purchase Cost ($)', key: 'purchaseCost' },
    { label: 'Old Location', key: 'oldLocation' },
    { label: 'New Location', key: 'newLocation' },
    { label: 'Asset Type', key: 'AssetType.name' }, // Updated to reflect the assetType name
    { label: 'Purchase Date', key: 'purchaseDate' },
    { label: 'Useful Life (yrs)', key: 'usefulLife' },
    { label: 'Custodian', key: 'custodian' },
    { label: 'Condition', key: 'condition' },
    { label: 'Entity', key: 'Entity.name' },
    { label: 'Branch', key: 'Branch.name' },
    { label: 'Verification Status', key: 'verificationStatus' },
  ];

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" component="h1" gutterBottom>
        Assets
      </Typography>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
        <TextField
          label="Search Assets"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAsset}
            style={{ marginRight: '16px' }}
          >
            Add Asset
          </Button>
          <CSVLink data={filteredAssets} headers={headers} filename="assets.csv" style={{ textDecoration: 'none' }}>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<DownloadIcon />}
            >
              Download CSV
            </Button>
          </CSVLink>
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Code</TableCell>
              <TableCell>Serial Number</TableCell>
              <TableCell>Vendor</TableCell>
              <TableCell>Purchase Cost($)</TableCell>
              <TableCell>Old Location</TableCell>
              <TableCell>New Location</TableCell>
              <TableCell>Asset Type</TableCell>
              <TableCell>Purchase Date</TableCell>
              <TableCell>Useful Life(yrs)</TableCell>
              <TableCell>Custodian</TableCell>
              <TableCell>Condition</TableCell>
              <TableCell>Entity</TableCell>
              <TableCell>Branch</TableCell>
              <TableCell>Verification Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredAssets.map((asset) => (
              <TableRow key={asset.id}>
                <TableCell>{asset.code}</TableCell>
                <TableCell>{asset.serialNumber}</TableCell>
                <TableCell>{asset.Vendor?.name || 'N/A'}</TableCell>
                <TableCell>{asset.purchaseCost}</TableCell>
                <TableCell>{asset.oldLocation}</TableCell>
                <TableCell>{asset.newLocation}</TableCell>
                <TableCell>{asset.AssetType?.name || 'N/A'}</TableCell> {/* Display the assetType name */}
                <TableCell>{dayjs(asset.purchaseDate).format('YYYY-MM-DD')}</TableCell>
                <TableCell>{asset.usefulLife}</TableCell>
                <TableCell>{asset.custodian}</TableCell>
                <TableCell>{asset.condition}</TableCell>
                <TableCell>{asset.Entity?.name || 'N/A'}</TableCell>
                <TableCell>{asset.Branch?.name || 'N/A'}</TableCell>
                <TableCell>{asset.verificationStatus || 'N/A'}</TableCell>
                <TableCell>
                  <IconButton color="primary" onClick={() => handleEdit(asset.id)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton color="primary" onClick={() => handleVerify(asset.id)}>
                    <AssignmentIcon />
                  </IconButton>
                  <IconButton color="secondary" onClick={() => handleDelete(asset.id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default AssetList;

